<template>
  <div>
    <page-title :pageTitle="$route.meta.title"></page-title>
    <product-create :goods_id="$route.params.goods_id || ''" @success="onSuccess"></product-create>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import { ProductCreate } from '@vhcl/product';
  import { isEmbed } from '../../../utils/utils';

  export default {
    name: 'ProductsCreate',
    components: {
      PageTitle,
      ProductCreate
    },
    methods: {
      onSuccess() {
        this.$router.push(`${isEmbed() ? '/embed' : ''}/interactive/products`);
      }
    }
  };
</script>

<style lang="less" scoped></style>
